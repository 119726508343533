import { React, useState } from "react";
import { Grid } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import Cart from "./Cart";
import { Button } from "./ButtonElement";

const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const Home = (props) => {
  const { products } = useShopify();
  const [shirtsEnabled, setShirtsEnabled] = useState(false);
  const [hoodiesEnabled, setHoodiesEnabled] = useState(false);
  const [miscEnabled, setMiscEnabled] = useState(false);
  const [productsEnabled, setproductsEnabled] = useState(true);
  const [latestEnabled, setLatestEnabled] = useState(false);

  const disableAllProducts = () => {
    setShirtsEnabled(false);
    setHoodiesEnabled(false);
    setMiscEnabled(false);
    setproductsEnabled(true);
    setLatestEnabled(false);
  };

  const displayShirts = () => {
    if (shirtsEnabled) {
      disableAllProducts();
      return;
    }
    setShirtsEnabled(true);
    setHoodiesEnabled(false);
    setMiscEnabled(false);
    setproductsEnabled(false);
    setLatestEnabled(false);
  };

  const displayHoodies = () => {
    if (hoodiesEnabled) {
      disableAllProducts();
      return;
    }
    setHoodiesEnabled(true);
    setShirtsEnabled(false);
    setMiscEnabled(false);
    setproductsEnabled(false);
    setLatestEnabled(false);
  };

  const displayMisc = () => {
    if (miscEnabled) {
      disableAllProducts();
      return;
    }
    setMiscEnabled(true);
    setHoodiesEnabled(false);
    setShirtsEnabled(false);
    setproductsEnabled(false);
    setLatestEnabled(false);
  };

  const displayLatest = () => {
    if (latestEnabled) {
      disableAllProducts();
      return;
    }
    setLatestEnabled(true);
    setMiscEnabled(false);
    setHoodiesEnabled(false);
    setShirtsEnabled(false);
    setproductsEnabled(false);
  };

  return (
    <>
      <div className="homepage-container">
        <div className="floating" onClick={disableAllProducts}>
          <img
            src="././Assets/logo.png"
            width="55"
            height="55"
            alt="BeefPlayZz Merch Store"
          />
        </div>
        <div className="App__header">
          <div className="carousel-image">
            <img src="././Assets/banner.png" alt="BeefPlayZz Merch Store" />
          </div>
        </div>

        {/* <div className="App__title">
          <h1 className="App__title-text">
            The Official BeefPlayZz Clothing Store
          </h1>
        </div> */}
        <div className="product-sort-buttons">
          <Button
            primary={latestEnabled}
            className="sort-buttons"
            onClick={displayLatest}
          >
            Latest Release
          </Button>
          <Button
            primary={shirtsEnabled}
            className="sort-buttons"
            onClick={displayShirts}
          >
            Shirts
          </Button>
          <Button
            primary={hoodiesEnabled}
            className="sort-buttons"
            onClick={displayHoodies}
          >
            Hoodies
          </Button>
          <Button
            primary={miscEnabled}
            className="sort-buttons"
            onClick={displayMisc}
          >
            Accessories
          </Button>
        </div>
      </div>
      {productsEnabled && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products.map((product, i) => (
                      <Grid item xs={6} md={4} lg={3} key={i}>
                        <Product product={product} history={props.history} />
                      </Grid>
                    ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {shirtsEnabled && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter((product) => product.productType === "T-Shirt")
                      .map((shirt, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={shirt} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {hoodiesEnabled && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(
                        (product) =>
                          product.productType === "Hoodie" &&
                          !product.handle.includes("blanket")
                      )
                      .map((hoodie, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={hoodie} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {miscEnabled && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (
                            product.productType === "Hat" ||
                            product.productType === "Phone Case" ||
                            product.productType === "Mug" ||
                            product.handle.includes("blanket") ||
                            product.title.includes("Sticker Pack") ||
                            product.title.includes("Joggers") ||
                            product.title.includes("Stickers")
                          ) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {latestEnabled && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products.map((product, i) => (
                      <Grid item xs={6} md={4} lg={3} key={i}>
                        <Product product={product} history={props.history} />
                      </Grid>
                    ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      <Cart />
      <Footer />
    </>
  );
};

export default Home;
